import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { StaticImage as Img } from 'gatsby-plugin-image'
import SectionTitle from './Components/SectionTitle'
import About from './style'
 
export default function AboutusSection(){
return(
<About>
  <Container>
    <Row className="align-items-center justify-content-center g-0">
      <Col xs="12" className="col-xxl-6 col-lg-5 col-md-10">
        <About.Image>
          <Img className="w-100" src="https://res.cloudinary.com/dv10simqm/image/upload/v1642103182/helios/Ciberseguridad/cibersec_2_2x_ozyr5w.png" alt="content" layout="fullWidth" placeholder="blurred"/>
        </About.Image>
      </Col>
      <Col className="col-xxl-6 col-lg-7 col-md-10">
        <About.Box>
          {/* Section Title */}
          <About.Content className="bg_light_gray">
              <SectionTitle
              subTitle="Ciberseguridad" 
              title="Incrementar tu ciberseguridad y comunicar la efectividad"
              text="En este entorno digital que avanza tan rápidamente, debes enfrentarte a la creciente necesidad de tomar el control de tu seguridad digital. Desde la perspectiva de las personas, los procesos y la tecnología, la ciberseguridad de tu empresa debe estar debidamente garantizada. <br /> · ¿Cómo puedes estar seguro de que tus sistemas y productos, así como tus proveedores, cumplen con el nivel adecuado de conformidad en comparación con los marcos estándares de ciberseguridad y las nuevas regulaciones? <br /> · ¿Puedes confiar en la imparcialidad y la independencia de una evaluación de terceros? <br /> · ¿Cómo puedes promover y comunicar tus logros?"
              subTitleProps={{mb:"10px",className:"subline",fontColor:"#000"}}
              titleProps={{mb:"10px",as:"h2"}}
              mb="69px" />
          </About.Content>
          {/*/ .Section Title */}
        </About.Box>
      </Col>
    </Row>
  </Container>
</About>


)
}