import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import FaqWidget from "./components/FaqWidget"
import FaqSectionData from "~data/pricing/pricing-2"
import Faq from "./style"
import { Link } from "gatsby"
const FaqSection = () => {
  return (
    <div className="container">
      <Faq>
        <Container>
          <Row className="justify-content-center">
            <Col className="col-xl-6 col-lg-8 col-md-9 col-xs-10 text-center">
              <small>Ciberseguridad</small>
              <Faq.Box mb="58px">
                <Faq.Title as="h1">Servicios</Faq.Title>
              </Faq.Box>
              <p>
                Servicios innovadores de ciberseguridad para ayudarlo a crecer
                con conﬁanza y desarrollar resiliencia cibernética desde adentro
                hacia afuera.
              </p>
            </Col>
          </Row>
          {/* Faqs */}
          <div className="row">
            {FaqSectionData.map(({ title, text, icon }, index) => {
              return (
                // <Col xs="12" className="col-lg-6 col-md-9 col-xs-11 wrapper_qa" key={"ppf" + index}>
                //     <FaqWidget className="text-center" icon={icon} title={title} text={text}/>
                // </Col>
                <div className="col-xs-12 col-md-6">
                  <div className="card card_faq">
                    <i className={icon}></i>
                    <div>
                      <h5>{title}</h5>
                      <p>{text}</p>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </Container>
      </Faq>
    </div>
  )
}

export default FaqSection
