import React from "react"
import { StaticImage as Img } from "gatsby-plugin-image"
import Content from "./style"
import { Link, SuperTag } from "~components"
import { Col, Container, Row } from "react-bootstrap"

export default function ContentSectionTwo() {
  return (
    <div className="container">
      <Content backgrounColor="#fff">
        <Content.ContentShape>
          {/* <Img
            src="../../../assets/image/project-management/l2-content-2-shape.png"
            alt="content"
            layout="constrained"
            placeholder="blurred"
          /> */}
        </Content.ContentShape>
        <Container>
          <Content.Block>
            <Row className="align-items-center justify-content-center justify-content-lg-start">
              <Col className="col-xl-7 col-lg-6 col-md-8 col-xs-10">
                <Content.Image>
                  <Img
                    src="https://res.cloudinary.com/dv10simqm/image/upload/v1642103060/helios/Ciberseguridad/cibersec_1_2x_vrcjti.png"
                    alt="content"
                    layout="fullWidth"
                    placeholder="blurred"
                  />
                  {/* <Content.ContentShape>
                    <Img
                      src="../../../assets/image/project-management/l2-content-2-shape-2.png"
                      alt="content"
                      layout="fullWidth"
                      placeholder="blurred"
                    />
                  </Content.ContentShape> */}
                </Content.Image>
              </Col>
              <Col xs="12" className="col-xl-5 col-lg-6 col-md-8 col-xs-10">
                <Content.Box>
                  <small>Ciberseguridad</small>
                  <Content.Title as="h2">
                    {" "}
                    <SuperTag value="Requisito" />{" "}
                  </Content.Title>

                  <Content.Text>
                    {" "}
                    <SuperTag value="La ciberseguridad es una prioridad para las empresas y los gobiernos. <br /> Las nuevas regulaciones y requisitos industriales han incrementado la necesidad de que organizaciones que actúan como terceros independientes puedan velar para minimizar el riesgo cibernético de tus sistemas, activos y productos / servicios. <br /> La ciberseguridad se debe convertir en un componente esencial de los sistemas, que esté en el ADN de cualquier línea de negocio." />{" "}
                  </Content.Text>
                  {/* <Content.Button to="/">Get Started Now</Content.Button> */}
                </Content.Box>
              </Col>
            </Row>
          </Content.Block>
        </Container>
      </Content>
    </div>
  )
}
