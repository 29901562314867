import React from "react"
import { PageWrapper } from "~components/Core"
import BannerOne from "~sections/Ciberseguridad/BannerOne"
import ContentTwo from "~sections/Ciberseguridad/ContentTwo"
import ServicesSection from "~sections/Ciberseguridad/Services"
import Faq from "~sections/Ciberseguridad/Faq/FaqSection"
import AboutusSection from "~sections/Ciberseguridad/About"
import FooterOne from "~sections/marketing/FooterOne"

const header = {
  headerClasses:
    "site-header site-header--menu-start dark-header site-header--sticky",
  containerFluid: true,
  // customLogo: Images.HeaderLogo,
}

export default function Ciberseguridad() {
  return (
    <PageWrapper headerConfig={header}>
      <BannerOne />
      <ContentTwo />
      <ServicesSection />
      <Faq />
      <AboutusSection />
      <FooterOne />
    </PageWrapper>
  )
}
