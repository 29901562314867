import React from 'react'
import ServicesCard from './Components/Card'
import Service from './style'
import SectionTitle from './Components/SectionTitle'
import { Col, Container, Row } from 'react-bootstrap'
export default function ServicesSection(){
return(
<Service>
  <Col className=" bg_light_gray pd_productos">
    <div className='container'>
    <Row className="row justify-content-center">
      <Col className="col-xl-8 text-center">
        <SectionTitle
        subTitle="Ciberseguridad" 
        title="Categorías de productos"
        titleProps={{mb:"40px", mbLG:"75px", fontColor:"#000"}}
       
          />
          <p className="d-block text-center">Generalmente se le presta atención solo después de que un sistema ha sido atacado o ya está en funcionamiento, para  nosotros es prioridad que esté presente desde el inicio de los proyectos.</p>
        </Col>
        
    </Row>
    <Row className="row justify-content-center">
      {/* Single Services */}
      <Col xs="12" className="col-xs-12 col-md-4 ">
      <ServicesCard className="bg_white" title="Seguridad para Endpoint" icon="fas fa-bell"  iconColor="#000"  text="Las mejores funciones de prevención, detección y respuesta con defensas avanzadas de varias capas para todos los dispositivos y sistemas operativos ahora están disponibles en la nube con una consola de seguridad inteligente con tecnología de IA y un solo agente." />
      </Col>
      {/*/ .Single Services */}
      {/* Single Services */}
      <Col className="col-xs-12 col-md-4">
      <ServicesCard className="bg_white" title="Seguridad de la información" icon="fas fa-layer-group"  iconColor="#000"  text="Proteja sus datos más confidenciales con un acceso seguro y mantenga la productividad de sus empleados, estén donde estén." />
      </Col>
      {/*/ .Single Services */}
      <Col xs="12" className="col-xs-12 col-md-4">
      <ServicesCard className="bg_white" title="Seguridad Web" icon="fas fa-envelope"  iconColor="#000"  text="Detenga las amenazas entrantes y salientes dirigidas a usuarios finales, información e infraestructura clave." />
      </Col>
      
    </Row>
    </div>
    
  </Col>
</Service>
)
}

